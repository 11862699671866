/* eslint-disable */

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoute from './ProtectRoute';

import ProtectedRouteEmployee from './ProtectedRouteEmployee';
import { TurnLeft, Wallet } from '@mui/icons-material';
import {
    ConditionalRenderDmtTable,
    ConditionalRenderCreateCustomer,
    ConditionalRenderDmtTransfer,
    ConditionalRenderCheckBenef,
    ConditionalRenderTransferPage
} from 'views/ConditionalRender';
import BillInvoice from 'views/BillsAndEmi/Bill-Reciept/BillInvoice';
// import Aeps_Authentication from 'views/wallets/AEPS/Aeps_Authentication';
// import { dmtType } from '../layout/MainLayout/index';
//Data from local storage
const dmtType = localStorage.getItem('dmtType');
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

//PUBLIC MESSAGE PAGE ROUTES
const PublicMessage = Loadable(lazy(() => import('views/PublicMessage')));
const PrimaryProfileUpdate = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/PrimaryProfileUpdate')));
const MandatoryPasswordReset = Loadable(lazy(() => import('views/Components/MandatoryResetPasswordModal')));

//MONEY TRANSFER PAGES ROUTING
const DmtTable = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/DmtTable')));
const DmtTableIserveu = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/DmtTable')));
const DmtTransfer = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/DmtTransfer')));
const CheckBeneficiaryStatus = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/CheckBeneficiaryStatus')));
const ListAllBeneficiary = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/ListAllBeneficiary')));
const FundTransfer = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/TransferPage')));
const ListMoneyTransfer = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/ListMoneyTransfer')));
const CreateBeneficiary = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/CreateBeneficiary')));
const CreateCustomer = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/CreateCustomer')));
const ReceiptPage = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/ReceiptPage')));
const DmtReport = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/DmtReport')));
const ReportReciept = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtFino/DmtReceipt')));
const CreateCustomerIservue = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/CreateCustomer')));
//money Transfer 2
const Dmt2Table = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/DmtTable')));
const Dmt2CreateCustomer = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/CreateCustomer')));
const Dmt2BeneFiciaryList = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/DmtTransfer')));
const Dmt2AddBeneFiciary = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/CheckBeneficiaryStatus')));
const Dmt2Transfer = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/TransferPage')));
//AEPS PAGES ROUTING
const AadhaarPay = Loadable(lazy(() => import('views/bankingServices/AEPS/AadhaarPay')));
const BalanceEnquiry = Loadable(lazy(() => import('views/bankingServices/AEPS/BalanceEnquiry')));
const CashWithdrawal = Loadable(lazy(() => import('views/bankingServices/AEPS/CashWithdrawal')));
const MiniStatementAadhaar = Loadable(lazy(() => import('views/bankingServices/AEPS/MiniStatementAadhaar')));
const MiniStatementReciept = Loadable(lazy(() => import('views/bankingServices/AEPS/Reciept/MiniStatementReciept')));
const BalanceEnquiryReciept = Loadable(lazy(() => import('views/bankingServices/AEPS/Reciept/BalanceEnquiryReciept')));
const AepsReport = Loadable(lazy(() => import('views/bankingServices/AEPS/AepsReport')));
const Aeps_Authentication = Loadable(lazy(() => import('views/bankingServices/AEPS/Aeps_Authentication')));
const AadhaarpayReport = Loadable(lazy(() => import('views/bankingServices/AEPS/AadhaarPayReport')));
const Kycauthentication = Loadable(lazy(() => import('views/bankingServices/AEPS/aepsKycAuthentication')));
const AepsRecieptPage = Loadable(lazy(() => import('views/bankingServices/AEPS/AepsRecieptPage')));
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
//BBPS
const ElectricityBill = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/ElectricityBill')));
const WaterBill = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/WaterBill')));
const BillPaymentsReport = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BillPaymentsReport')));
const BbpsRegistration = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BbpsRegistration/BbpsRegistration')));
const BillPayments = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BillPayments')));
const Recharges = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Mobile/RechargePage')));
const RechargeReport = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Mobile/RechargeReport')));
const BbpsReceipt = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BbpsRecieptPage')));
const RechargeReceipt = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Mobile/RechargeReceipt')));
const BillReceipt = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillReciept')));
const BillPrint = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillPrint')));
const BillList = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillJist')));
const BillInvoices = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillInvoice')));
const BbpsBillerReport = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BbpsBillerReport')));
const BbpsSettlement = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BbpsBillerSettlements')));
//DTH
const DthPage = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Dth/RechargePage')));
//icici registration
const Merchantmodel = Loadable(lazy(() => import('views/iciciRegistration/MerchantModel')));
const Address = Loadable(lazy(() => import('views/iciciRegistration/Address')));
const KYC = Loadable(lazy(() => import('views/iciciRegistration/kyc')));
const IciciPending = lazy(() => import('views/iciciRegistration/iciciUnauthorized/IciciPending'));
//Iserveu MATM registration
const IserveuMATMRegistration = Loadable(lazy(() => import('views/bankingServices/MATM/Iserveu-MATM-Registration')));
const MatmReport = Loadable(lazy(() => import('views/bankingServices/MATM/MatmReport')));
//Common Services
const CommonService = Loadable(lazy(() => import('views/CommonService/GovermentServices/GovermentServices')));
const SubServices = Loadable(lazy(() => import('views/CommonService/GovermentServices/SubServices')));
const InsuranceRedirect = Loadable(lazy(() => import('views/CommonService/InsuranceRedirect')));
const InsuranceComingsoon = Loadable(lazy(() => import('views/CommonService/InsuranceComingsoonPage')));
const HelpingTools = Loadable(lazy(() => import('views/CommonService/HelpingTools/HelpingTools')));
const InsuranceReport = Loadable(lazy(() => import('views/CommonService/InsuranceReport')));

//PANCARD
const RegisterPancard = Loadable(lazy(() => import('views/Pancard/PancardPage')));
const BuyCouponPage = Loadable(lazy(() => import('views/Pancard/BuyCouponPage')));
const ViewCouponPage = Loadable(lazy(() => import('views/Pancard/ViewCouponPage')));
const ViewRegistrationPage = Loadable(lazy(() => import('views/Pancard/ViewRegDetails')));
const EditResubmitPage = Loadable(lazy(() => import('views/Pancard/EditResubmitPage')));

//Bookings
const TrainTicket = Loadable(lazy(() => import('views/BillsAndEmi/trainTickets/BookTrainTicket')));

//CUSTOMER SUPPORT SECTION
const TicketManagement = Loadable(lazy(() => import('views/CustomerSupport/TicketManagement')));
const SupportChat = Loadable(lazy(() => import('views/CustomerSupport/CustomerChat')));

//atm upi
const AtmUpi = Loadable(lazy(() => import('views/bankingServices/UpiAtm/UpiAtm')));
const AtmUpiHistory = Loadable(lazy(() => import('views/bankingServices/UpiAtm/UpiAtmHistory')));
const AtmUpiMessage = Loadable(lazy(() => import('views/bankingServices/UpiAtm/upiAtmUnFreeze')));
//BC or premimum BC conversion
const UpgradePremiumBc = Loadable(lazy(() => import('views/Others/PremiumBC/UpgradePremiumBc')));
const PremumBcClassCard = Loadable(lazy(() => import('views/Others/PremiumBC/ClassCards')));
//POWER BI REPORTS
const PowerBiReport = Loadable(lazy(() => import('views/Components/PowerBi/PowerBiReport')));
//Transaction receipt page
const TransactionReceiptPage = Loadable(lazy(() => import('views/bankingServices/ReceiptPage')));
//merchants

const Transactions = Loadable(lazy(() => import('../views/BalanceSection/BalanceToRedeem')));
const Wallet3 = Loadable(lazy(() => import('../views/BalanceSection/Wallet3')));
const SettlementRequest = Loadable(lazy(() => import('../views/BalanceSection/SettlementRequest')));
const LoadWallet = Loadable(lazy(() => import('../views/BalanceSection/LoadWallet')));
const UpiWalletRequestMerchant = Loadable(lazy(() => import('../views/BalanceSection/UpiWalletRequest')));
const WalletRequestQrUpi = Loadable(lazy(() => import('../views/BalanceSection/WalletRequestQr')));
const ProfilePage = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/ProfilePage')));
const EditProfile = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/EditProfile')));
const AllTransactions = Loadable(lazy(() => import('../views/BalanceSection/AllTransactions')));
const Credit = Loadable(lazy(() => import('../views/bankingServices/Credit/Credit')));
// pos
const PosReport = Loadable(lazy(() => import('../views/bankingServices/POS/POSReport1')));
//Special pages
const UnauthorizedSection = Loadable(lazy(() => import('views/pages/authentication/authentication3/UnauthorizedSection')));
//Mobile banking
const MobileBankingRegistration = Loadable(lazy(() => import('views/bankingServices/MobileBanking/MobileBankingRegistration')));
const MobileBankingList = Loadable(lazy(() => import('views/bankingServices/MobileBanking/MobileBankingList')));
const MobileBankingView = Loadable(lazy(() => import('views/bankingServices/MobileBanking/MobileBankingView')));
const MobileBankingUserTransactions = Loadable(lazy(() => import('views/bankingServices/MobileBanking/MobileBankingUserTransactions')));
const MobileBankingAllTransactions = Loadable(lazy(() => import('views/bankingServices/MobileBanking/MobileBankingAllTransactions')));
//Qr
const Qr = Loadable(lazy(() => import('views/bankingServices/QR/Qr')));
//Rd services
const RDServices = Loadable(lazy(() => import('views/CommonService/RDService/RDService')));
const RDServices2 = Loadable(lazy(() => import('views/CommonService/RDService/RDService2')));
const RDServices3 = Loadable(lazy(() => import('views/CommonService/RDService/RDService3')));
const RDServices4 = Loadable(lazy(() => import('views/CommonService/RDService/RDServices4')));
//Reward
const PrizeWheel = Loadable(lazy(() => import('views/Reward/PrizeWheel')));
//HelpingTools
const HelpLine = Loadable(lazy(() => import('views/HelpLine/HelpLine')));
const TestIframe = Loadable(lazy(() => import('views/Test/IframeTest')));
//bbps Auth
const BbpsForm = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BBPSAuthuntication/BbpsAuthForm')));
const Bbpspending = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BBPSAuthuntication/BbpsPending')));
const BbpsAuth = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BBPSAuthuntication/BBPSAuthuntication')));
const BbpsOnBoardWidget = Loadable(lazy(() => import('views/dashboard/Default/BbpsAuthFormWidget')));
const BbpsOnboardPending = Loadable(lazy(() => import('views/dashboard/Default/BbpsPending')));
//Employee Login
const EmployeeDashboard = Loadable(lazy(() => import('views/Employee/Dashboard')));
const EmployeeProfileImage = Loadable(lazy(() => import('views/Employee/ProfileImageUpload')));
const EmployeeProfile = Loadable(lazy(() => import('views/Employee/Profile')));
const CreateMerchant = Loadable(lazy(() => import('views/Employee/CreateMerchant')));
const ListMerchants = Loadable(lazy(() => import('views/Employee/ListMerchants')));
const ViewEmployeeMerchant = Loadable(lazy(() => import('views/Employee/ViewMerchants')));
const EmployeeTransactionMerchant = Loadable(lazy(() => import('views/Employee/TransactionMerchant')));
const EmployeeEditMerchant = Loadable(lazy(() => import('views/Employee/EditMerchant')));
const Qronboard = Loadable(lazy(() => import('views/Employee/merchantTable/QrOnboard')));
const PosOnboard = Loadable(lazy(() => import('views/Employee/merchantTable/PosOnboard')));
const OnBoardingStatus = Loadable(lazy(() => import('views/Employee/merchantTable/OnboardingStatus')));
const TlEm = Loadable(lazy(() => import('views/Employee/TL')));
const ListTLEm = Loadable(lazy(() => import('views/Employee/ListTL')));
const CreateEmployeeEm = Loadable(lazy(() => import('views/Distributor/CreateEmployee')));
const ListEmployeeEm = Loadable(lazy(() => import('views/Distributor/ListEmployee')));
const QrOnboardingEmployee = Loadable(lazy(() => import('views/Employee/Onboarding/QrOnboarding')));
const PosOnboardingEmployee = Loadable(lazy(() => import('views/Employee/Onboarding/PosOnboarding')));
const BcAgentActivationEmployee = Loadable(lazy(() => import('views/Employee/Onboarding/BcAgentActivation')));
const OnboardingEmployee = Loadable(lazy(() => import('views/Employee/Onboarding/Onboarding')));
const MerchantsEmployee = Loadable(lazy(() => import('views/Employee/Merchants')));
const ViewMerchantsEmployee = Loadable(lazy(() => import('views/Employee/ViewMerchants')));
const TransactionMerchantEmployee = Loadable(lazy(() => import('views/Employee/TransactionMerchant')));
const IncentiveElegible = Loadable(lazy(() => import('views/Employee/IncentiveEligibleReport')));
//MERCHANT EMPLOYEE >>>>>>>>>>>
const CreateMerchantEmployee = Loadable(lazy(() => import('../views/MerchantEmployee/CreateMerchantEmployee')));
const ListAllMerchantsEmployee = Loadable(lazy(() => import('views/Employee/ListAllMerchants')));
const MerchantDetails = Loadable(lazy(() => import('views/Employee/MerchantDetails')));
const TransactionMerchantsEmp = Loadable(lazy(() => import('views/Employee/TransactionMerchants')));
const AllBcEmp = Loadable(lazy(() => import('views/Employee/AllBc')));

import { DistributorRoutes } from './RolebasedRoutes/distributorRoutes';
import { SuperdistributorRoutes } from './RolebasedRoutes/superdistributorRoutes';
import { MisRoutes } from './RolebasedRoutes/misRoutes';
// ==============================|| MAIN ROUTING ||============================== //

const EmployeeRoutes = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteEmployee element={<EmployeeDashboard />} />
        },
        {
            path: 'profile-image-upload',
            element: <ProtectedRouteEmployee element={<EmployeeProfileImage />} />
        },

        {
            path: 'profile',
            element: <ProtectedRouteEmployee element={<EmployeeProfile />} />
        },
        {
            path: 'Create-Merchant',
            element: <ProtectedRouteEmployee element={<CreateMerchant />} />
        },
        {
            path: 'list-merchant',
            element: <ProtectedRouteEmployee element={<ListMerchants />} />
        },
        {
            path: 'listallmerchants',
            element: <ProtectedRouteEmployee element={<ListAllMerchantsEmployee />} />
        },
        {
            path: 'view-merchants',
            element: <ProtectedRouteEmployee element={<ViewEmployeeMerchant />} />
        },
        {
            path: 'transaction-merchant',
            element: <ProtectedRouteEmployee element={<EmployeeTransactionMerchant />} />
        },
        {
            path: 'indexeditmerchants',
            element: <ProtectedRouteEmployee element={<EmployeeEditMerchant />} />
        },
        {
            path: 'qronboard',
            element: <ProtectedRouteEmployee element={<Qronboard />} />
        },
        {
            path: 'posonboard',
            element: <ProtectedRouteEmployee element={<PosOnboard />} />
        },
        {
            path: 'status',
            element: <ProtectedRouteEmployee element={<OnBoardingStatus />} />
        },
        {
            path: 'tl',
            element: <ProtectedRouteEmployee element={<TlEm />} />
        },
        {
            path: 'listtl',
            element: <ProtectedRouteEmployee element={<ListTLEm />} />
        },
        {
            path: 'createemployee',
            element: <ProtectedRouteEmployee element={<CreateEmployeeEm />} />
        },
        {
            path: 'listemployee',
            element: <ProtectedRouteEmployee element={<ListEmployeeEm />} />
        },
        {
            path: 'allbc',
            element: <ProtectedRouteEmployee element={<AllBcEmp />} />
        },
        {
            path: 'merchant-view',
            element: <ProtectedRouteEmployee element={<MerchantDetails />} />
        },
        {
            path: 'transaction-merchants',
            element: <ProtectedRouteEmployee element={<TransactionMerchantsEmp />} />
        },
        {
            path: 'onboarding',
            element: <ProtectedRouteEmployee element={<OnboardingEmployee />} />
        },
        {
            path: 'qr-onboarding',
            element: <ProtectedRouteEmployee element={<QrOnboardingEmployee />} />
        },
        {
            path: 'pos-onboarding',
            element: <ProtectedRouteEmployee element={<PosOnboardingEmployee />} />
        },
        {
            path: 'bcactivation',
            element: <ProtectedRouteEmployee element={<BcAgentActivationEmployee />} />
        },
        {
            path: 'merchants',
            element: <ProtectedRouteEmployee element={<MerchantsEmployee />} />
        },
        {
            path: 'view-merchants',
            element: <ProtectedRouteEmployee element={<ViewMerchantsEmployee />} />
        },
        {
            path: 'transaction-merchant',
            element: <ProtectedRouteEmployee element={<TransactionMerchantEmployee />} />
        },
        {
            path: 'incentive-elegible',
            element: <ProtectedRouteEmployee element={<IncentiveElegible />} />
        }
    ]
};

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/merchant-home',
            element: <ProtectedRoute element={<DashboardDefault />} />
        },
        {
            path: 'unauthorized-section',
            element: <ProtectedRoute element={<UnauthorizedSection />} />
        },
        {
            path: 'pending-section',
            element: <ProtectedRoute element={<IciciPending />} />
        },
        {
            path: 'public-message',
            element: <ProtectedRoute element={<PublicMessage />} />
        },
        {
            path: 'transaction-receipt',
            element: <ProtectedRoute element={<TransactionReceiptPage />} />
        },
        // {
        //     path: 'dashboard',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <ProtectedRoute element={<DashboardDefault />} />
        //         }
        //     ]
        // },
        {
            path: 'dmt',
            children: [
                {
                    path: 'dmtTable',
                    element: <ProtectedRoute element={<ConditionalRenderDmtTable />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                },
                {
                    path: 'iserveuDmtTable',
                    element: <ProtectedRoute element={<DmtTableIserveu />} />
                },
                {
                    path: 'fundTransfer',
                    element: <ProtectedRoute element={<ConditionalRenderTransferPage />} />
                },
                {
                    path: 'dmtTransfer',
                    element: <ProtectedRoute element={<ConditionalRenderDmtTransfer />} />
                },
                {
                    path: 'checkBeneficiary',
                    element: <ProtectedRoute element={<ConditionalRenderCheckBenef />} />
                },
                {
                    path: 'ListBeneficiary',
                    element: <ProtectedRoute element={<ListAllBeneficiary />} />
                },

                {
                    path: 'ListMoneyTransfer',
                    element: <ProtectedRoute element={<ListMoneyTransfer />} />
                },
                {
                    path: 'CreateBeneficiary',
                    element: <ProtectedRoute element={<CreateBeneficiary />} />
                },
                {
                    path: 'Createcustomer',
                    element: <ProtectedRoute element={<ConditionalRenderCreateCustomer />} />
                },
                {
                    path: 'transferReceipt',
                    element: <ProtectedRoute element={<ReceiptPage />} />
                },
                {
                    path: 'dmtreport',
                    element: <ProtectedRoute element={<DmtReport />} />
                },
                {
                    path: 'dmtreciept',
                    element: <ProtectedRoute element={<ReportReciept />} />
                }
            ]
        },
        {
            path: 'dmt2',
            children: [
                {
                    path: 'dmt-customerlist',
                    element: <ProtectedRoute element={<Dmt2Table />} />
                },
                {
                    path: 'dmt-createcustomer',
                    element: <ProtectedRoute element={<Dmt2CreateCustomer />} />
                },
                {
                    path: 'dmt-beneficiarylist',
                    element: <ProtectedRoute element={<Dmt2BeneFiciaryList />} />
                },
                {
                    path: 'dmt-addbeneficiary',
                    element: <ProtectedRoute element={<Dmt2AddBeneFiciary />} />
                },
                {
                    path: 'dmt-transfer',
                    element: <ProtectedRoute element={<Dmt2Transfer />} />
                }
            ]
        },
        {
            path: 'mobile-banking',
            children: [
                {
                    path: 'mobilebanking-registration',
                    element: <ProtectedRoute element={<MobileBankingRegistration />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                },
                {
                    path: 'mobilebanking-list',
                    element: <ProtectedRoute element={<MobileBankingList />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                },
                {
                    path: 'mobilebanking-view',
                    element: <ProtectedRoute element={<MobileBankingView />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                },
                {
                    path: 'mobilebanking-usertransaction',
                    element: <ProtectedRoute element={<MobileBankingUserTransactions />} />
                },
                {
                    path: 'mobilebanking-alltransaction',
                    element: <ProtectedRoute element={<MobileBankingAllTransactions />} />
                }
            ]
        },
        {
            path: 'credit',
            element: <ProtectedRoute element={<Credit />} />
        },
        {
            path: 'atmupi',
            element: <ProtectedRoute element={<AtmUpi />} />
        },
        {
            path: 'atmupi-history',
            element: <ProtectedRoute element={<AtmUpiHistory />} />
        },
        {
            path: 'atmupi-message',
            element: <ProtectedRoute element={<AtmUpiMessage />} />
        },
        {
            path: 'aeps',
            children: [
                {
                    path: 'aadhaarpay',
                    element: <ProtectedRoute element={<AadhaarPay />} />
                },
                {
                    path: 'balanceEnquiry',
                    element: <ProtectedRoute element={<BalanceEnquiry />} />
                },
                {
                    path: 'cashWithdrawal',
                    element: <ProtectedRoute element={<CashWithdrawal />} />
                },
                {
                    path: 'miniStatementAadhaar',
                    element: <ProtectedRoute element={<MiniStatementAadhaar />} />
                },
                {
                    path: 'miniStatement',
                    element: <ProtectedRoute element={<MiniStatementReciept />} />
                },
                {
                    path: 'BalanceEnquiryReciept',
                    element: <ProtectedRoute element={<BalanceEnquiryReciept />} />
                },
                {
                    path: 'iciciregistration',
                    element: <ProtectedRoute element={<Merchantmodel />} />
                },
                {
                    path: 'Address',
                    element: <ProtectedRoute element={<Address />} />
                },
                {
                    path: 'kyc',
                    element: <ProtectedRoute element={<KYC />} />
                },
                {
                    path: 'aepsreport',
                    element: <ProtectedRoute element={<AepsReport />} />
                },
                {
                    path: 'aadhaarpayreport',
                    element: <ProtectedRoute element={<AadhaarpayReport />} />
                },
                {
                    path: 'aepsauthentication',
                    element: <ProtectedRoute element={<Aeps_Authentication />} />
                },
                {
                    path: 'kycauthentication',
                    element: <ProtectedRoute element={<Kycauthentication />} />
                },
                {
                    path: 'aepsreceipt',
                    element: <ProtectedRoute element={<AepsRecieptPage />} />
                }
            ]
        },
        {
            path: 'bbps',
            children: [
                {
                    path: 'billPayments',
                    element: <ProtectedRoute element={<BillPayments />} />
                },
                {
                    path: 'bbpsform',
                    element: <ProtectedRoute element={<BbpsForm />} />
                },
                {
                    path: 'bbpspending',
                    element: <ProtectedRoute element={<Bbpspending />} />
                },
                {
                    path: 'bbpsauth',
                    element: <ProtectedRoute element={<BbpsAuth />} />
                },
                {
                    path: 'bbpsonbaord',
                    element: <ProtectedRoute element={<BbpsOnBoardWidget />} />
                },
                {
                    path: 'bbpsonboardpending',
                    element: <ProtectedRoute element={<BbpsOnboardPending />} />
                },
                {
                    path: 'bill-receipt',
                    element: <ProtectedRoute element={<BillReceipt />} />
                },
                {
                    path: 'bill-print',
                    element: <ProtectedRoute element={<BillPrint />} />
                },
                {
                    path: 'bill-list',
                    element: <ProtectedRoute element={<BillList />} />
                },
                {
                    path: 'bill-invoice',
                    element: <ProtectedRoute element={<BillInvoices />} />
                }
            ]
        },
        {
            path: 'common-service',
            children: [
                {
                    path: 'government-services',
                    element: <ProtectedRoute element={<CommonService />} />
                },
                {
                    path: 'sub-services',
                    element: <ProtectedRoute element={<SubServices />} />
                },
                {
                    path: 'insurance',
                    element: <ProtectedRoute element={<InsuranceRedirect />} />
                },
                {
                    path: 'helpingtools',
                    element: <ProtectedRoute element={<HelpingTools />} />
                },
                {
                    path: 'insurance-report',
                    element: <ProtectedRoute element={<InsuranceReport />} />
                },
                {
                    path: 'insurance-comingsoon',
                    element: <ProtectedRoute element={<InsuranceComingsoon />} />
                }
            ]
        },
        {
            path: 'pancard',
            children: [
                {
                    path: 'pancard-register',
                    element: <ProtectedRoute element={<RegisterPancard />} />
                },
                {
                    path: 'buy-coupon',
                    element: <ProtectedRoute element={<BuyCouponPage />} />
                },
                {
                    path: 'view-coupon',
                    element: <ProtectedRoute element={<ViewCouponPage />} />
                },
                {
                    path: 'view-registration',
                    element: <ProtectedRoute element={<ViewRegistrationPage />} />
                },
                {
                    path: 'edit-resubmit',
                    element: <ProtectedRoute element={<EditResubmitPage />} />
                }
            ]
        },
        {
            path: 'bookings',
            children: [
                {
                    path: 'train-ticket',
                    element: <ProtectedRoute element={<TrainTicket />} />
                }
            ]
        },
        {
            path: 'customer-support',
            children: [
                {
                    path: 'ticket-management',
                    element: <ProtectedRoute element={<TicketManagement />} />
                },
                {
                    path: 'question-answer',
                    element: <ProtectedRoute element={<SupportChat />} />
                }
            ]
        },
        {
            path: 'bbps',
            children: [
                {
                    path: 'bbpsRegistration',
                    element: <ProtectedRoute element={<BbpsRegistration />} />
                },
                {
                    path: 'bbpsreport',
                    element: <ProtectedRoute element={<BillPaymentsReport />} />
                },
                {
                    path: 'bbpsreceipt',
                    element: <ProtectedRoute element={<BbpsReceipt />} />
                },
                {
                    path: 'rechargepage',
                    element: <ProtectedRoute element={<Recharges />} />
                },
                {
                    path: 'rechargereport',
                    element: <ProtectedRoute element={<RechargeReport />} />
                },
                {
                    path: 'rechargereceipt',
                    element: <ProtectedRoute element={<RechargeReceipt />} />
                },
                {
                    path: 'dthpage',
                    element: <ProtectedRoute element={<DthPage />} />
                },
                {
                    path: 'bbpsbillerreport',
                    element: <ProtectedRoute element={<BbpsBillerReport />} />
                },
                {
                    path: 'bbpsbillerSettlementreport',
                    element: <ProtectedRoute element={<BbpsSettlement />} />
                }
            ]
        },
        {
            path: 'matm',
            children: [
                {
                    path: 'iserveu-matm-registration',
                    element: <ProtectedRoute element={<IserveuMATMRegistration />} />
                },
                {
                    path: 'matm-report',
                    element: <ProtectedRoute element={<MatmReport />} />
                }
            ]
        },
        {
            path: 'distributor',
            children: [DistributorRoutes]
        },
        {
            path: 'super-distributor',
            children: [SuperdistributorRoutes]
        },
        {
            path: 'mis',
            children: [MisRoutes]
        },
        {
            path: 'employee',
            children: [EmployeeRoutes]
        },
        {
            path: 'merchants',
            children: [
                {
                    path: 'transactions',
                    element: <ProtectedRoute element={<Transactions />} />
                },
                {
                    path: 'Wallet3',
                    element: <ProtectedRoute element={<Wallet3 />} />
                },

                {
                    path: 'wallet',
                    element: <ProtectedRoute element={<LoadWallet />} />
                },
                {
                    path: 'upi',
                    element: <ProtectedRoute element={<UpiWalletRequestMerchant />} />
                },
                {
                    path: 'requestupi',
                    element: <ProtectedRoute element={<WalletRequestQrUpi />} />
                },
                {
                    path: 'profile',
                    element: <ProtectedRoute element={<ProfilePage />} />
                },
                {
                    path: 'edit-profile',
                    element: <ProtectedRoute element={<EditProfile />} />
                },
                {
                    path: 'primary-profile-update',
                    element: <ProtectedRoute element={<PrimaryProfileUpdate />} />
                },
                {
                    path: 'mandatory-password-reset',
                    element: <ProtectedRoute element={<MandatoryPasswordReset />} />
                },
                {
                    path: 'all-transactions',
                    element: <ProtectedRoute element={<AllTransactions />} />
                },
                {
                    path: 'settlement-request',
                    element: <ProtectedRoute element={<SettlementRequest />} />
                },
                {
                    path: 'create-merchant-employee',
                    element: <ProtectedRoute element={<CreateMerchantEmployee />} />
                }
            ]
        },
        {
            path: 'pos',
            children: [
                {
                    path: 'pos-report',
                    element: <ProtectedRoute element={<PosReport />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                }
            ]
        },
        {
            path: 'qr',
            children: [
                {
                    path: 'myqr',
                    element: <ProtectedRoute element={<Qr />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                }
            ]
        },
        {
            path: 'rd',
            children: [
                {
                    path: 'rdservices',
                    element: <ProtectedRoute element={<RDServices />} />
                },
                {
                    path: 'rdservices2',
                    element: <ProtectedRoute element={<RDServices2 />} />
                },
                {
                    path: 'rdservices3',
                    element: <ProtectedRoute element={<RDServices3 />} />
                },
                {
                    path: 'rdservices4',
                    element: <ProtectedRoute element={<RDServices4 />} />
                }
            ]
        },
        {
            path: 'premium-bc',
            children: [
                {
                    path: 'premium-bc-class-cards',
                    element: <ProtectedRoute element={<PremumBcClassCard />} />
                }
            ]
        },
        {
            path: '/',
            children: [
                {
                    path: 'helpline',
                    element: <ProtectedRoute element={<HelpLine />} />
                },
                {
                    path: 'iframe-test',
                    element: <ProtectedRoute element={<TestIframe />} />
                },
                {
                    path: 'upgrade-premium-bc',
                    element: <ProtectedRoute element={<UpgradePremiumBc />} />
                },
                {
                    path: 'power-bi-report',
                    element: <ProtectedRoute element={<PowerBiReport />} />
                },
                {
                    path: 'wheel-of-prices',
                    element: <ProtectedRoute element={<PrizeWheel />} />
                }
            ]
        }
    ]
};

export default MainRoutes;

import { globalNotifyError, globalNotifySuccess } from './AlertToast';
import $ from 'jquery';

export const handleCapture = (onSuccess) => {
    const xml = `<?xml version="1.0"?>
    <PidOptions ver="1.0">
      <Opts fCount="1" fType="2" iCount="0" pCount="0" pType="0" pgCount="2" format="0" iType="0" pidVer="2.0" timeout="10000" pTimeout="20000" wadh="18f4CEiXeXcfGXvgWA/blxD+w2pw7hfQPY45JMytkPw=" posh="UNKNOWN" env="P" />
      <CustOpts>
        <Param name="mantrakey" value="" />
      </CustOpts>
    </PidOptions>`;

    $.ajax({
        type: 'CAPTURE',
        async: false,
        crossDomain: true,
        url: 'https://127.0.0.1:11100/rd/capture',
        data: xml,
        contentType: 'text/xml; charset=utf-8',
        processData: false,
        success: function (data) {
            $('div.spanner').addClass('show');
            $('#txtPidData').val(data);
            const xmlString = data;

            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

            // Extract the errInfo attribute value
            const errInfo = xmlDoc.getElementsByTagName('Resp')[0].getAttribute('errInfo');
            if (errInfo === 'Success.') {
                globalNotifySuccess('Finger Capture Success');
                onSuccess && onSuccess(data);
            } else {
                globalNotifyError(errInfo);
                const info = false;
                onSuccess && onSuccess(info);
            }
            return false;
        },
        error: function (jqXHR) {
            $('div.spanner').removeClass('show');
            globalNotifyError('Please Connect Your Biometric Device');
            const res = {
                httpStatus: jqXHR.status,
                err: getHttpError(jqXHR)
            };
            console.log('res', res);
        }
    });
};

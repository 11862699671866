/* eslint-disable */

import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';

const commonEndPoint = 'api/V2/';
let merchantEmpID;
const isEmployee = localStorage.getItem('isEmployee');
if (isEmployee === 'true') {
    merchantEmpID = localStorage.getItem('empId');
}
export const commonPost = async (apiEndPoint, payload, params) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    let empId;
    empId = localStorage.getItem('empId');
    if (empId == 'undefined') {
        empId = '';
    }
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Custom-Header': empId.toString(),
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.post(
            endPoint,
            payload,

            {
                headers,
                params
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};
export const commonGetRequest = async (apiEndPoint, params) => {
    const endPoint = commonEndPoint + apiEndPoint;
    const token = localStorage.getItem('token');
    let empId;
    empId = localStorage.getItem('empId');
    if (empId == 'undefined') {
        empId = '';
    }
    const headers = {
        Authorization: `Bearer ${token}`,
        'Header-Merchant-Employee': merchantEmpID,
        'Custom-Header': empId.toString(),
        'Content-Type': 'application/json'
    };
    try {
        const response = await axiosInstanceLive.get(
            endPoint,

            {
                headers,
                params
            }
        );
        return response;
    } catch (error) {
        console.log(error);
        handleCatchError(error);
        return error;
    }
};

export const GET_API_ENDPOINTS = {
    createagentStates: 'moneytransfer/distributor/createagent',
    Approvewalletrequest: 'moneytransfer/distributor/Approvewalletrequest',
    addBankDetails: 'moneytransfer/distributor/addBankDetails',
    profile: 'moneytransfer/distributor/profile',
    getMccForDistributor: 'moneytransfer/distributor/Mcccodes',
    verifyPan: 'moneytransfer/distributor/verifyPancard',
    verifyBank: 'moneytransfer/distributor/verifyBank',
    verifyAadhaar: 'moneytransfer/distributor/verifyAdhaar',
    getDistrict: 'moneytransfer/distributor/district',
    getState: 'moneytransfer/distributor/states',
    getMcc: 'moneytransfer/distributor/mcc',
    getBussiness: 'moneytransfer/distributor/business',
    getCity: 'moneytransfer/distributor/fetchCity',
    getBank: 'moneytransfer/distributor/fetchBank',
    selectAllEmployee: 'moneytransfer/distributor/selectAllEmployees',
    getViewMerchant: 'moneytransfer/distributor/onboard/viewMerchant',
    listSubdistributor: 'moneytransfer/distributor/listSubdistributor'
};
export const POST_API_ENDPOINTS = {
    listmerchants: 'moneytransfer/distributor/listmerchants',
    PosOnboarding: 'moneytransfer/distributor/PosOnboarding',
    storePosOnboarding: 'moneytransfer/distributor/storePosOnboarding',
    QrOnboarding: 'moneytransfer/distributor/QrOnboarding',
    districtSelection: 'moneytransfer/distributor/districtSelection',
    citySelection: 'moneytransfer/distributor/citySelection',
    storeQrOnboarding: 'moneytransfer/distributor/storeQrOnboarding',
    ResendOTP: 'moneytransfer/distributor/ResendOTP',
    VerifyOTP: 'moneytransfer/distributor/VerifyOTP',
    Storeagentdetails: 'moneytransfer/distributor/Storeagentdetails',
    StatusApproveWalletrequest: 'moneytransfer/distributor/StatusApproveWalletrequest',
    StatusRejectWalletrequest: 'moneytransfer/distributor/StatusRejectWalletrequest',
    updateBankDetails: 'moneytransfer/distributor/updateBankDetails',
    deleteBankDetails: 'moneytransfer/distributor/deleteBankDetails',
    storeBankDetails: 'moneytransfer/distributor/storeBankDetails',
    storeEmployee: 'moneytransfer/distributor/storeEmployee',
    getEmployeeList: 'moneytransfer/distributor/getEmployeeList',
    balance: 'moneytransfer/distributor/balance',
    indexeditmerchants: 'moneytransfer/distributor/indexeditmerchants',
    StoreEditmerchants: 'moneytransfer/distributor/StoreEditmerchants',
    DistributorSetresetpassword: 'moneytransfer/distributor/DistributorSetresetpassword',
    allTransaction: 'moneytransfer/distributor/allTransaction',
    transactionMerchants: 'moneytransfer/distributor/transaction/merchants',
    viewmerchants: 'moneytransfer/distributor/viewmerchants',
    createTm: 'moneytransfer/distributor/StoreTM',
    listTm: 'moneytransfer/distributor/listTM',
    editTm: 'moneytransfer/distributor/StoreeditTM',
    createTl: 'moneytransfer/distributor/StoreTL',
    listTl: 'moneytransfer/distributor/listTL',
    incentiveReport: 'moneytransfer/distributor/ListcheckBCExport ',
    editTl: 'moneytransfer/distributor/StoreeditTL',
    deactivate: 'moneytransfer/distributor/DistStatusUpdate',
    emailOtp: 'moneytransfer/distributor/sendEmailOtp',
    emailOtpVerify: 'moneytransfer/distributor/VerifyEmailOTP',
    onboard: 'moneytransfer/distributor/StoreagentdetailsNew',
    qronboard: 'moneytransfer/distributor/onboard/QrOnboarding',
    posOnboard: 'moneytransfer/distributor/onboard/PosOnboarding',
    Aeps: 'moneytransfer/distributor/storeAepsDetails',
    changeTm: 'moneytransfer/distributor/onchangeTm',
    changeTl: 'moneytransfer/distributor/onchangeTl',
    mapping: 'moneytransfer/distributor/UpdateMerchantEmployeeMapping',
    qrStatus: 'moneytransfer/distributor/QrOnboarding',
    posStatus: 'moneytransfer/distributor/PosOnboarding',
    listAllmerchants: 'moneytransfer/distributor/listAllmerchants',
    allbc: 'moneytransfer/distributor/listBc',
    viewMerchantDetails: 'moneytransfer/distributor/viewMerchantDetails',
    merchantTransactions: 'moneytransfer/distributor/view/MerchantTransactions',
    StoredetailsSubDistributor: 'moneytransfer/distributor/StoredetailsSubDistributor',
    viewSubdistributor: 'moneytransfer/distributor/viewSubdistributor',
    editSubdistributor: 'moneytransfer/distributor/EditdetailsSubDistributor',
    listmytransactions: 'moneytransfer/distributor/listmytransactions',
    creditToAgent: 'moneytransfer/distributor/CreditbalancetoAgent',
    creditToDist: 'moneytransfer/distributor/CreditbalancetoSuperdistributor',
    ssoBcDashboard: 'moneytransfer/distributor/sso/AgentLogin',
    ConvertAsAgent: 'moneytransfer/distributor/onboard/ConvertAsAgent',
    graph: 'moneytransfer/distributor/graphData',
    listSoundBox: 'moneytransfer/distributor/soundbox/get-details',
    soundBox: 'moneytransfer/distributor/soundbox/activation',
    checkPermission: 'moneytransfer/distributor/checkPermission',
    signDistAgreement: 'moneytransfer/distributor/sign-DistAgreement',
    addProfileImage: 'moneytransfer/distributor/addProfileImg',
    callToAgents: 'moneytransfer/distributor/Calltoagents'
};
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const listmerchantsApi = async (payload) => {
    const response = await commonPost(POST_API_ENDPOINTS.listmerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const posOnboardingApi = async (payload) => {
    //API FOR POS ONBOARDING STATUS
    const response = await commonPost(POST_API_ENDPOINTS.PosOnboarding, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const storePosOnboardingApi = async (payload) => {
    //API FOR STORING POS ONBOARDING
    const response = await commonPost(POST_API_ENDPOINTS.storePosOnboarding, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const qrOnboardingApi = async (payload) => {
    //API FOR QR ONBOARDING DATA
    const response = await commonPost(POST_API_ENDPOINTS.QrOnboarding, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const districtSelectionApi = async (payload) => {
    //API FOR FETCHING DISTRICTS
    const response = await commonPost(POST_API_ENDPOINTS.districtSelection, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const citySelectionApi = async (payload) => {
    //API FOR FETCHING CITIES
    const response = await commonPost(POST_API_ENDPOINTS.citySelection, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const storeQrOnboardingApi = async (payload) => {
    //API FOR STORING
    const response = await commonPost(POST_API_ENDPOINTS.storeQrOnboarding, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const createagentStatesApi = async (params) => {
    //API FOR FETCHING STATES AND DISTRICTS FOR CREATE MERCHANTS
    const response = await commonGetRequest(GET_API_ENDPOINTS.createagentStates, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const resendOtpApi = async (payload) => {
    //API FOR SENDING AND RESENDING OTP
    const response = await commonPost(POST_API_ENDPOINTS.ResendOTP, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const verifyOtpApi = async (payload) => {
    //API FOR VERIFYING OTP
    const response = await commonPost(POST_API_ENDPOINTS.VerifyOTP, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const storeagentdetailsApi = async (payload) => {
    //API FOR VERIFYING OTP
    const response = await commonPost(POST_API_ENDPOINTS.Storeagentdetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const approvewalletrequestApi = async (params) => {
    //API FOR FETCHING STATES AND DISTRICTS FOR CREATE MERCHANTS
    const response = await commonGetRequest(GET_API_ENDPOINTS.Approvewalletrequest, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const statusApproveWalletrequestApi = async (payload) => {
    //API FOR status approve wallet request
    const response = await commonPost(POST_API_ENDPOINTS.StatusApproveWalletrequest, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const statusRejectWalletrequestApi = async (payload) => {
    //API FOR status approve wallet request
    const response = await commonPost(POST_API_ENDPOINTS.StatusRejectWalletrequest, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const addBankDetailsApi = async (params) => {
    //API FOR BANK DETAILS
    const response = await commonGetRequest(GET_API_ENDPOINTS.addBankDetails, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const updateBankDetailsApi = async (payload) => {
    //API FOR UPDATE BANK DETAILS
    const response = await commonPost(POST_API_ENDPOINTS.updateBankDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const deleteBankDetailsApi = async (payload) => {
    //API FOR DELETING BANK DETAILS
    const response = await commonPost(POST_API_ENDPOINTS.deleteBankDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const storeBankDetailsApi = async (payload) => {
    //API FOR STORE BANK DETAILS
    const response = await commonPost(POST_API_ENDPOINTS.storeBankDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const storeEmployeeApi = async (payload) => {
    //API FOR STORE EMPLOYEE DETAILS
    const response = await commonPost(POST_API_ENDPOINTS.storeEmployee, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getEmployeeListApi = async (payload, params) => {
    //API FOR LISTING EMPLOYEES
    const response = await commonPost(POST_API_ENDPOINTS.getEmployeeList, payload, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const balanceApi = async (payload) => {
    //API FOR FETCHING BALANCE
    const response = await commonPost(POST_API_ENDPOINTS.balance, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const indexeditmerchantsApi = async (payload) => {
    //API FOR INDEX EDIT MERCHANT
    const response = await commonPost(POST_API_ENDPOINTS.indexeditmerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const profileApi = async (params) => {
    //API FOR PROFILE DETAILS
    const response = await commonGetRequest(GET_API_ENDPOINTS.profile, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const getMccForDistributor = async (params) => {
    //API FOR GETTING MCC DETAILS
    const response = await commonGetRequest(GET_API_ENDPOINTS.getMccForDistributor, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listSubdistributorsApi = async (params) => {
    //API FOR GETTING LIST OF SUB DISTRIBUTORS
    const response = await commonGetRequest(GET_API_ENDPOINTS.listSubdistributor, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const storeEditmerchantsApi = async (payload) => {
    //API FOR STORE EDIT MERCHANT
    const response = await commonPost(POST_API_ENDPOINTS.StoreEditmerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const distributorSetresetpasswordApi = async (payload) => {
    //API FOR DISTRIBUTOR RESET PASSWORD
    const response = await commonPost(POST_API_ENDPOINTS.DistributorSetresetpassword, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const allTransactionApi = async (payload) => {
    //API FOR ALL TRANSACTION
    const response = await commonPost(POST_API_ENDPOINTS.allTransaction, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const transactionMerchantsApi = async (payload) => {
    //API FOR TRANSACTION MERCHANTS
    const response = await commonPost(POST_API_ENDPOINTS.transactionMerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

export const viewmerchantsApi = async (payload) => {
    //API FOR VIEW MERCHANTS
    const response = await commonPost(POST_API_ENDPOINTS.viewmerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const createTmApi = async (payload) => {
    //API FOR CREATE TM
    const response = await commonPost(POST_API_ENDPOINTS.createTm, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const StoredetailsSubDistributorApi = async (payload) => {
    //API FOR CREATING SUB DISTRIBUTOR
    const response = await commonPost(POST_API_ENDPOINTS.StoredetailsSubDistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listTmApi = async (payload) => {
    //API FOR CREATE TM
    const response = await commonPost(POST_API_ENDPOINTS.listTm, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const viewSubdistributorApi = async (payload) => {
    //API FOR FETCHING DETAILS OF ONE SUB DISTRIBUTOR
    const response = await commonPost(POST_API_ENDPOINTS.viewSubdistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const editTmApi = async (payload) => {
    //API FOR CREATE TM
    const response = await commonPost(POST_API_ENDPOINTS.editTm, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const editSubdistributorApi = async (payload) => {
    //API FOR EDITING DETAILS OF ONE SUB DISTRIBUTOR
    const response = await commonPost(POST_API_ENDPOINTS.editSubdistributor, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const createTlApi = async (payload) => {
    //API FOR CREATE TL
    const response = await commonPost(POST_API_ENDPOINTS.createTl, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listmytransactionsApi = async (payload) => {
    //API FOR FETCHING DISTRIBUTOR TRANSACTIONS>>
    const response = await commonPost(POST_API_ENDPOINTS.listmytransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listTlApi = async (payload) => {
    //API FOR CREATE TL
    const response = await commonPost(POST_API_ENDPOINTS.listTl, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const incentiveReportApi = async (payload) => {
    //API FOR CREATE TL
    const response = await commonPost(POST_API_ENDPOINTS.incentiveReport, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const editTlApi = async (payload) => {
    //API FOR CREATE TL
    const response = await commonPost(POST_API_ENDPOINTS.editTl, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const deactivateApi = async (payload) => {
    //API FOR DEACTIVATE
    const response = await commonPost(POST_API_ENDPOINTS.deactivate, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getVerifyPanApi = async (params) => {
    //API FOR PAN VERIFY
    const response = await commonGetRequest(GET_API_ENDPOINTS.verifyPan, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getVerifyBank = async (params) => {
    //API FOR PAN VERIFY
    const response = await commonGetRequest(GET_API_ENDPOINTS.verifyBank, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getVerifyAadhaar = async (params) => {
    //API FOR AADAR VERIFY
    const response = await commonGetRequest(GET_API_ENDPOINTS.verifyAadhaar, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getDistrictApi = async (params) => {
    //API FOR Get Dist
    const response = await commonGetRequest(GET_API_ENDPOINTS.getDistrict, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getStateApi = async (params) => {
    //API FOR Get State
    const response = await commonGetRequest(GET_API_ENDPOINTS.getState, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getMccApi = async (params) => {
    //API FOR Get Mcc
    const response = await commonGetRequest(GET_API_ENDPOINTS.getMcc, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getBussinessApi = async (params) => {
    //API FOR Get Bussiness
    const response = await commonGetRequest(GET_API_ENDPOINTS.getBussiness, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getCityApi = async (params) => {
    //API FOR Get CITY
    const response = await commonGetRequest(GET_API_ENDPOINTS.getCity, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getEmailOtp = async (payload) => {
    //API FOR Email OTp
    const response = await commonPost(POST_API_ENDPOINTS.emailOtp, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getEmailOtpVErify = async (payload) => {
    //API FOR Email OTp Verify
    const response = await commonPost(POST_API_ENDPOINTS.emailOtpVerify, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const onboardApi = async (payload) => {
    //API FOR Onboard
    const response = await commonPost(POST_API_ENDPOINTS.onboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getBankApi = async (params) => {
    //API FOR Get Bank
    const response = await commonGetRequest(GET_API_ENDPOINTS.getBank, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const qronboardApi = async (payload) => {
    //API FOR QR ONBOARD
    const response = await commonPost(POST_API_ENDPOINTS.qronboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const posOnboardApi = async (payload) => {
    //API FOR POS ONBOARD
    const response = await commonPost(POST_API_ENDPOINTS.posOnboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const AepsApi = async (payload) => {
    //API FOR Get Aeps

    const response = await commonPost(POST_API_ENDPOINTS.Aeps, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getListAllEmployee = async (params) => {
    //API FOR Get employee
    const response = await commonGetRequest(GET_API_ENDPOINTS.selectAllEmployee, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const changeTmApi = async (payload) => {
    //API FOR Tm change

    const response = await commonPost(POST_API_ENDPOINTS.changeTm, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const changeTlApi = async (payload) => {
    //API FOR Tl change

    const response = await commonPost(POST_API_ENDPOINTS.changeTl, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const mappingApi = async (payload) => {
    //API FOR Mapping Api

    const response = await commonPost(POST_API_ENDPOINTS.mapping, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getViewMerchantApi = async (params) => {
    //API FOR Get onboarded merchant
    const response = await commonGetRequest(GET_API_ENDPOINTS.getViewMerchant, params)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const qrStatusChecker = async (payload) => {
    //API FOR qr status checker

    const response = await commonPost(POST_API_ENDPOINTS.qrStatus, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const posStatusChecker = async (payload) => {
    //API FOR pos status checker

    const response = await commonPost(POST_API_ENDPOINTS.posStatus, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listAllMerchantsApi = async (payload) => {
    //API FOR onboarded list
    const response = await commonPost(POST_API_ENDPOINTS.listAllmerchants, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const listAllBc = async (payload) => {
    //API FOR list bc's
    const response = await commonPost(POST_API_ENDPOINTS.allbc, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const viewMerchantDetails = async (payload) => {
    //API FOR view merchants
    const response = await commonPost(POST_API_ENDPOINTS.viewMerchantDetails, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const transactionMerchant = async (payload) => {
    //API FOR view merchants
    const response = await commonPost(POST_API_ENDPOINTS.merchantTransactions, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const creditToAgentApi = async (payload) => {
    //API FOR credit merchants
    const response = await commonPost(POST_API_ENDPOINTS.creditToAgent, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const creditToDistApi = async (payload) => {
    //API FOR credit dist
    const response = await commonPost(POST_API_ENDPOINTS.creditToDist, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const ssoBcDashboardDistApi = async (payload) => {
    //API FOR redirecting to bc dashboard from distributor
    const response = await commonPost(POST_API_ENDPOINTS.ssoBcDashboard, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const ConvertDistAsAgentApi = async (payload) => {
    //API FOR converting distributor into BC agent
    const response = await commonPost(POST_API_ENDPOINTS.ConvertAsAgent, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const graphApi = async (payload) => {
    const response = await commonPost(POST_API_ENDPOINTS.graph, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const getSoundBoxList = async (payload) => {
    //API FOR Get ListAllSoundbox
    const response = await commonPost(POST_API_ENDPOINTS.listSoundBox, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const AddSoundBoxDistApi = async (payload) => {
    //API FOR add sound box
    const response = await commonPost(POST_API_ENDPOINTS.soundBox, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const checkPermissionDistributor = async (payload) => {
    //API FOR check permissions in distributor
    const response = await commonPost(POST_API_ENDPOINTS.checkPermission, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const signDistAgreementApi = async (payload) => {
    //API FOR E sign agreement distributor
    const response = await commonPost(POST_API_ENDPOINTS.signDistAgreement, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const addProfileImageApi = async (payload) => {
    //API for distributor profile image upload
    const response = await commonPost(POST_API_ENDPOINTS.addProfileImage, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};
export const callToAgentApi = async (payload) => {
    //API FOR Agent Call
    const response = await commonPost(POST_API_ENDPOINTS.callToAgents, payload)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
    return response;
};

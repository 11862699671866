/* eslint-disable */
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { message } from 'antd';

export function globalNotifySuccess(messageProp, duration = 3) {
    message.success(messageProp, duration);
}

export function globalNotifyError(messageProp, duration = 3) {
    message.error(messageProp, duration);
}
export function globalNotifyWarning(messageProp, duration = 3) {
    message.warning(messageProp, duration);
}

function AlertToast() {
    return (
        <div>
            <ToastContainer />
        </div>
    );
}

export default AlertToast;

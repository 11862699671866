import React from 'react';
/* eslint-disable */

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import { useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TextField } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { handleCatchError } from 'utils/CatchHandler';
import Spinner from 'views/Components/LoadingSinner';
import { globalNotifySuccess } from 'views/utilities/AlertToast';
import { useNavigate } from 'react-router';
import { dmtFundTransferApi, generateOtpApi } from 'utils/API/Merchant APIs/DmtApiCalls';
import Link from '@mui/material/Link';
import { useDispatch } from 'react-redux';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';
const commonStyles = {
    m: 0
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function FundTransferEkyc({ showModal, benAcc, id, beneName, beneId, paymentMode, amount, data, closeModalView, otp_refernce }) {
    const [open, setOpen] = React.useState(false);
    const [OtpPin, setOtpPin] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [OtpRefrenceId, setOtpRefrenceId] = useState(otp_refernce);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dmt_type = 2;
    useEffect(() => {
        setOpen(true);
        // if (showModal) {
        //     handleGenerateOtp();
        // }
    }, [showModal]);
    const handleClose = () => {
        closeModalView(false);
        setOpen(false);
    };
    const handleOtpChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 6);
        setOtpPin(amountValue);
    };
    const handleGenerateOtp = async () => {
        try {
            setOtpPin('');
            setIsLoading(true);
            const agentid = localStorage.getItem('id');
            const customerId = id;
            const BeneName = beneName;
            const paymentMode = 'IMPS';
            const BeneAccountNo = benAcc;
            const payload = {
                agentid,
                BeneName,
                customerId,
                BeneAccountNo,
                amount,
                dmt_type,
                paymentMode
            };
            const response = await generateOtpApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setOtpRefrenceId(response?.data?.OTPReferenceID);
            } else {
                handleClose();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleFundTransfer = async () => {
        try {
            setIsLoading(true);
            const agentid = localStorage.getItem('id');
            const customerId = id;
            const Pid = 0;
            const payload = {
                agentid,
                customerId,
                beneId,
                amount,
                paymentMode,
                OtpPin,
                OtpRefrenceId,
                Pid,
                dmt_type
            };
            const response = await dmtFundTransferApi(payload);
            const orderId = response?.data?.data?.orderId;

            if(orderId){
                const isTransfer = true;
                navigate('/dmt/dmtreciept', { state: { data, orderId, isTransfer } });
            }
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
            }
            setIsLoading(false);
            dispatch(fetchWalletBalanceAsync());
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    '& .MuiDialog-paper': {
                        width: '100%',
                        maxWidth: '600px',
                        margin: '0 auto',
                        padding: { xs: '10px', sm: '20px' },
                        '@media (max-width: 600px)': {
                            width: '90%'
                        }
                    }
                }}
            >
                {/* <DialogTitle>{'Verify Your EKYC'}</DialogTitle> */}
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        ...commonStyles,
                        borderRadius: 2,
                        marginBottom: 2,
                        padding: { xs: '10px', sm: '20px' }
                    }}
                >
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="OTP Number"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={OtpPin}
                                    onChange={handleOtpChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} container justifyContent="flex-end">
                                <Link component="button" variant="body2" onClick={handleGenerateOtp}>
                                    Resend OTP
                                </Link>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button variant="contained" fullWidth disabled={isLoading || !OtpPin} onClick={handleFundTransfer}>
                                Verify
                            </Button>
                        </DialogActions>
                    </>
                </Box>
            </Dialog>

            <Spinner loading={isLoading} />
        </>
    );
}

export default FundTransferEkyc;

/* eslint-disable */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { profileApi } from 'utils/API/Distributor APIs/DistributorAPiCalls';
import { getProfile } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { handleCatchError } from 'utils/CatchHandler';
export const fetchProfileAsync = createAsyncThunk('fetchImage', async () => {
    try {
        const types = localStorage.getItem('type');
        if (types === 'merchants') {
            const response = await fetchWalletBalance();
            return response;
        } else if (types === 'distributor') {
            const response = await fetchDistProfile();
            return response;
        }
    } catch (error) {
        throw new Error('Failed to fetch Profile');
    }
});
// console.log(type);
const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        image: null,
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfileAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProfileAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.image = action.payload;
            })
            .addCase(fetchProfileAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

//Function to fetch profile
const fetchWalletBalance = async () => {
    try {
        const response = await getProfile();
        const profileImage = response?.data?.data?.profile_img;
        return profileImage;
    } catch (error) {
        console.log(error.message);
        const checkMerchantLogin = localStorage.getItem('type');
        if (checkMerchantLogin === 'merchants') {
            handleCatchError(error);
        }
    }
};
const fetchDistProfile = async () => {
    try {
        const response = await profileApi();
        const profileImage = response?.data?.data?.proff;
        return profileImage;
    } catch (error) {
        console.log(error.message);
    }
};

export const { resIn, resOut } = profileSlice.actions;
export default profileSlice.reducer;

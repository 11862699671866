/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};

const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist'); // Check localStorage at runtime
    return isBCWalletTrue === 'true' ? defaultTitle : bcTitle;
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Wallets = {
    id: 'newUtilities',
    // title: 'distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'distributor/dashboard',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons87',
            title: getTitle('BC Dashboard', 'Convert As BC Agent'),
            type: 'item',
            url: 'distributor/bc-dashboard-redirection',
            breadcrumbs: true
        },
        // {
        //     id: 'tabler-icons2',
        //     title: 'Profile',
        //     type: 'item',
        //     url: 'distributor/profile',
        //     breadcrumbs: true
        // },
        {
            id: 'tabler-icons2111',
            title: 'All Merchants',
            type: 'item',
            url: 'distributor/all-merchants',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons222111',
            title: 'All Bc Agents',
            type: 'item',
            url: 'distributor/all-bc',
            breadcrumbs: true
        },
        // {
        //     id: 'icons22312',
        //     title: 'Onboarding',
        //     type: 'collapse',
        //     children: [
        //         {
        //             id: 'tabler-icons31210',
        //             title: 'Onboarding',
        //             type: 'item',
        //             url: 'distributor/onboarding',
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'tabler-icons532211',
        //             title: 'List',
        //             type: 'item',
        //             url: '/distributor/merchants',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'tabler-icons001',
            title: 'Soundbox',
            type: 'item',
            url: 'distributor/soundboxlist',
            breadcrumbs: true
        },
        {
            id: 'icons22311',
            title: 'TM',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons310',
                    title: 'TM',
                    type: 'item',
                    url: 'distributor/tm',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5321',
                    title: 'List TM',
                    type: 'item',
                    url: '/distributor/listtm',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231',
            title: 'TL',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3120',
                    title: 'TL',
                    type: 'item',
                    url: 'distributor/tl',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53221',
                    title: 'List TL',
                    type: 'item',
                    url: '/distributor/listtl',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'tabler-icons2221111',
            title: 'Credit',
            type: 'item',
            url: 'distributor/credit-tosuperdist',
            breadcrumbs: true
        },
        // {
        //     id: 'tabler-icons3',
        //     title: 'Merchants',
        //     type: 'item',
        //     url: 'distributor/merchants',
        //     breadcrumbs: false
        // },
        {
            id: 'tabler-icons92',
            title: getTitleLabel3(),
            type: 'item',
            url: 'distributor/manage-subdistributor',
            breadcrumbs: true
        },
        {
            id: 'icons2211',
            title: 'Employee',
            type: 'collapse',
            children: [
                {
                    id: 'sample-page211',
                    title: 'Create Employee',
                    type: 'item',
                    url: '/distributor/createemployee',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons521',
                    title: 'List Employee',
                    type: 'item',
                    url: '/distributor/listemployee',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'tabler-icons4',
            title: 'Wallet Request',
            type: 'item',
            url: 'distributor/walletrequest',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons9',
            title: 'Incentive Eligible Report',
            type: 'item',
            url: '/distributor/IncentiveReport',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons5',
            title: 'Approve Wallet',
            type: 'item',
            url: '/distributor/approvewallet',
            breadcrumbs: false
        },

        {
            id: 'tabler-icons6',
            title: 'Bank Details',
            type: 'item',
            url: '/distributor/bankdetails',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons7',
            title: 'Transaction History',
            type: 'item',
            url: '/distributor/transaction-history',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons8',
            title: 'My Transactions',
            type: 'item',
            url: '/distributor/my-transactions',
            breadcrumbs: false
        }
        // ]
        // }
        // {
        //     id: 'inventory-main',
        //     title: 'Inventory Management',
        //     type: 'collapse',
        //     children: [
        //         {
        //             id: 'inventory-sub-1',
        //             title: 'Add Device Request',
        //             type: 'item',
        //             url: 'distributor/inventory/add-device-request',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'inventory-sub-2',
        //             title: 'List Device Request',
        //             type: 'item',
        //             url: 'distributor/inventory/list-device-request',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'inventory-sub-3',
        //             title: 'List Received Request',
        //             type: 'item',
        //             url: '/distributor/inventory/list-request-received',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'inventory-sub-4',
        //             title: 'List Dispatched Orders',
        //             type: 'item',
        //             url: '/distributor/inventory/list-dispatched-orders',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'inventory-sub-5',
        //             title: 'Device Delivery',
        //             type: 'item',
        //             url: '/distributor/listtl',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default Wallets;

/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { customerEkycApi, ekycOtpVerifyApi, getCustomerOtpApi } from 'utils/API/Merchant APIs/DmtApiCalls';

const commonStyles = {
    m: 0
};
export default function CreateCustomerIserveu() {
    const navigate = useNavigate();
    const dmt_type = 2;
    const [fname, setFname] = React.useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lname, setLname] = React.useState('');
    const [phone, setPhoneno] = React.useState('');
    const [errName, setErrName] = useState('');
    const [errLname, setErrLname] = useState('');
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const [aadharno, setAadharno] = useState('');
    const [errorAadhar, setErrorAadhar] = useState('');
    const [showOtpField, setShowOtpField] = useState(false);
    const [OTPPin, setOTPPin] = useState('');
    const [OtpId, setOtpId] = useState('');
    const [kycId, setKycId] = useState('');
    const [address, setAddress] = useState('');
    const [errorAddress, setErrorAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [errorPin, setErrorPin] = useState('');
    const location = useLocation();
    const mobilefromSearch = location?.state?.search;
    const senderno = phone;
    const agentid = localStorage.getItem('id');
    //API CALL FOR EKYC
    const handleEkyc = async () => {
        try {
            setOTPPin('');
            setIsLoading(true);
            const CustomerMobileNo = phone;
            const CustomerName = fname + ' ' + lname;
            const AadharNo = aadharno;
            const Pid = 0;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                AadharNo,
                Pid,
                dmt_type
            };
            const response = await customerEkycApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setShowOtpField(response?.data?.success);
                setKycId(response?.data?.KYCRequestID);
                setOtpId(response?.data?.OTPRequestID);
            }
            if (response?.data?.ResponseCode === 2) {
                globalNotifyError('Please resent the otp');
                setKycId(response?.data?.KYCRequestID);
            } else if (response?.data?.ResponseCode === 101) {
                handleClose();
                // const item = datas;
                // navigate('/dmt/dmtTransfer', { state: { item, filteredData, index } });
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API CALL FOR RESENT OTP
    const handleResentOtp = async () => {
        try {
            setOTPPin('');
            setIsLoading(true);
            const CustomerMobileNo = phone;
            const CustomerName = fname + ' ' + lname;
            const AadharNo = aadharno;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                AadharNo,
                dmt_type
            };
            const response = await getCustomerOtpApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setShowOtpField(response?.data?.success);
                setOtpId(response?.data?.OTPRequestID);
            } else if (response?.data?.ResponseCode === 101) {
                handleClose();
                // const item = datas;
                // navigate('/dmt/dmtTransfer', { state: { item, filteredData, index } });
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API CALL FOR EKYC OTP VERIFY
    const handleEkycVErifyOtp = async () => {
        try {
            setIsLoading(true);
            const CustomerMobileNo = phone;
            const CustomerName = fname + ' ' + lname;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                OTPPin,
                OTPRequestId: OtpId,
                KYCRequestId: kycId,
                pincode,
                address,
                dmt_type
            };
            const response = await ekycOtpVerifyApi(payload);
            setIsLoading(false);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                // const item = datas;
                // navigate('/dmt/dmtTransfer', { state: { item, filteredData, index } });
                navigate('/dmt2/dmt-customerlist');
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //Validations
    const validateMobile = () => {
        const re = /^[0-9]{10}$/;
        if (!re.test(senderno)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };
    const validateName = () => {
        const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        const minLength = 3;
        if (!re.test(fname)) {
            setErrName('Please enter a valid First Name');
        } else if (fname.length < minLength) {
            setErrName(`Name should be at least ${minLength} characters long`);
        } else {
            setErrName('');
        }
    };
    const validateLName = () => {
        const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if (!re.test(lname)) {
            setErrLname('Please enter a valid Last Name');
        } else {
            setErrLname('');
        }
    };

    const validateAadhar = () => {
        const re = /^\d{12}$/;
        if (!re.test(aadharno)) {
            setErrorAadhar('Please enter a valid Aadhaar card number');
        } else {
            setErrorAadhar('');
        }
    };
    const validateAddress = () => {
        if (!address) {
            setErrorAddress('Please enter a valid address');
        } else {
            setErrorAddress('');
        }
    };
    const validatePin = () => {
        const re = /^\d{6}$/;
        if (!re.test(pincode)) {
            setErrorPin('Please enter a valid pincode');
        } else {
            setErrorPin('');
        }
    };
    const handleAadharChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 12);
        setAadharno(amountValue);
    };
    const handleOtpChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 6);
        setOTPPin(amountValue);
    };
    const handleAddress = (e) => {
        let inputValue = e.target.value.replace(/[^a-zA-Z0-9\s,.\-]/g, '');
        inputValue = inputValue.replace(/\s+/g, '');
        inputValue = inputValue.slice(0, 150);
        setAddress(inputValue);
    };
    const handlePinCodeChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 6);
        setPincode(amountValue);
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    useEffect(() => {
        const handleCheckmerchant = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');
            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus) {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        handleCheckmerchant();
        setPhoneno(mobilefromSearch);
    }, []);
    return (
        <div>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    marginBottom: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Create Customer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <RouterLink to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </RouterLink>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Create Customer</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 5
                    }
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                required
                                label="First Name:"
                                value={fname}
                                onChange={(event) => (event.target.value.length < 20 ? setFname(event.target.value) : null)}
                                helperText={errName}
                                error={Boolean(errName)}
                                onBlur={validateName}
                                // onBlur={validateAadhaar}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={lname}
                                onChange={(event) => (event.target.value.length < 20 ? setLname(event.target.value) : null)}
                                helperText={errLname}
                                error={Boolean(errLname)}
                                onBlur={validateLName}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={phone}
                                helperText={errorMsg}
                                error={Boolean(errorMsg)}
                                onBlur={validateMobile}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Aadhaar Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={aadharno}
                                onChange={handleAadharChange}
                                helperText={errorAadhar}
                                error={Boolean(errorAadhar)}
                                onBlur={validateAadhar}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                fullWidth
                                required
                                value={address}
                                onChange={handleAddress}
                                helperText={errorAddress}
                                error={Boolean(errorAddress)}
                                onBlur={validateAddress}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Pincode"
                                variant="outlined"
                                fullWidth
                                required
                                value={pincode}
                                onChange={handlePinCodeChange}
                                helperText={errorPin}
                                error={Boolean(errorPin)}
                                onBlur={validatePin}
                            />
                        </Grid>
                    </Grid>
                    {/* )} */}
                    {showOtpField && (
                         <Grid container spacing={2}>
                         <Grid item xs={12} sm={12}>
                             <TextField
                                 id="outlined-basic"
                                 label="OTP Number"
                                 variant="outlined"
                                 fullWidth
                                 required
                                 value={OTPPin}
                                 onChange={handleOtpChange}
                             />
                         </Grid>
                         <Grid item xs={12} sm={12} container justifyContent="flex-end">
                             <Link component="button" variant="body2" onClick={handleResentOtp}>
                                 Resend OTP
                             </Link>
                         </Grid>
                     </Grid>
                    )}
                    <>
                        {!showOtpField && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button
                                    variant="contained"
                                    type="primary"
                                    disabled={!aadharno || isLoading || !address || !pincode || !fname || !lname}
                                    size="medium"
                                    fullWidth
                                    onClick={handleEkyc}
                                >
                                    submit
                                </Button>
                            </Box>
                        )}
                    </>
                    {/* )} */}
                    {showOtpField && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button variant="contained" type="primary" size="medium" fullWidth loading={isLoading} onClick={handleEkycVErifyOtp}>
                                Verify Customer
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal isOpen={merchantModalOpen} handleclose={handleBcModalClose} isPending={isBcConvertPending} />
            )}
            <Spinner loading={isCheckLoading || isLoading} />
        </div>
    );
}

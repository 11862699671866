import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { HomeOutlined } from '@ant-design/icons';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import {
    createBenefApi,
    deleteBeneficiaryApi,
    getIservueLimitApi,
    moneytransferBenefDetailsApi
} from 'utils/API/Merchant APIs/DmtApiCalls';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';

// const senderno = localStorage.getItem('sendernumber');
const commonStyles = {
    m: 0
};
export default function DmtTransfer() {
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const email = localStorage.getItem('email_id');
    const uid = localStorage.getItem('id');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [benfDetails, setBenfDetails] = useState([]);
    const [maxLimit, setMaxLimit] = useState('');
    const [isFailed, setIsFailed] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailedMessage, setIsFailedMessage] = useState('');
    const [isSuccessMessage, setIsSuccessMessage] = useState('');
    const [beneficiaryMobile, setBeneficiaryMobile] = useState('');
    const [beneficiary_id, setBenefId] = useState('');
    const [beneficiary_account, setBenefAcc] = useState('');
    const [beneficiary_ifsc, setBenefIfsc] = useState('');
    const [customerMobile, setCustomerMobile] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const type = 'fino';
    const data = location?.state?.item || location?.state?.data;
    const filteredData = location?.state?.filteredData;

    const index = location?.state?.index;
    const senderno = data?.customermob || location?.state?.search || localStorage.getItem('sendernumber');

    //API CALL FUNCTIONS STARTS HERE>>>>>>>>>>>>>>>>>>>>

    const getTransactionLimit = async () => {
        const CustomerMobileNo = data?.customermob;
        const type = 'iserveu';
        try {
            const payload = { CustomerMobileNo, type };
            const response = await getIservueLimitApi(payload);
            const maximumLimit = response?.data?.sendupto;
            setMaxLimit(maximumLimit);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    const CreateBenefToIserveu = async (row, indexBenef) => {
        const benefname = row?.benefName;
        const benelname = row?.benefLname;
        const AccountNo = row?.benefAcc;
        const ifsc = row?.benefIfsc;
        const MobileNo = row?.benefMobile;
        const senderId = row?.id;
        const transfername = localStorage.getItem('dmtType');
        try {
            setCreateLoading(true);
            const payload = { senderno, benefname, benelname, AccountNo, ifsc, MobileNo, senderId, transfername };
            const response = await createBenefApi(payload);
            setCreateLoading(false);

            if (response?.status === 201) {
                const message = response?.data?.message;
                globalNotifyError(message);
            } else if (response?.status === 200) {
                navigate('/dmt2/dmt-transfer', { state: { data, row, filteredData, index, indexBenef, senderno } });
            } else {
                globalNotifyError('Transfer request failed, please try again.');
            }
        } catch (error) {
            globalNotifyError('Something went wrong.');
            setCreateLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    const customerDetails = async () => {
        try {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
            setIsLoading(true);
            const payload = { uid, senderno, email, type };
            const response = await moneytransferBenefDetailsApi(payload);
            const metaData = response?.data?.data;
            const benfDetails = metaData?.benfDetails;
            setBenfDetails(benfDetails);
            getTransactionLimit();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    useEffect(() => {
        customerDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted]);

    //API CALL FUNCTIONS END HERE>>>>>>>>>>>>>>>>>>>>>

    function addbeneficiary() {
        localStorage.setItem('sendernumber', senderno);
        navigate('/dmt2/dmt-addbeneficiary', { state: { data, senderno } });
    }
    function ListBeneficiary() {
        navigate('/dmt/ListBeneficiary', { state: { data, senderno } });
    }
    function ListMoneyTransfer() {
        navigate('/dmt/ListMoneyTransfer', { state: { data, senderno } });
    }

    const handleTransferClick = (row, indexBenef) => {
        localStorage.setItem('sendernumber', senderno);
        if (row?.id) {
            navigate('/dmt2/dmt-transfer', { state: { data, row, filteredData, index, indexBenef, senderno } });
        } else {
            CreateBenefToIserveu(row, indexBenef);
        }
    };

    const confirm = () => {
        deleteBeneficiary();
        cancel();
    };
    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    useEffect(() => {
        if (isFailed) {
            globalNotifyError(isFailedMessage);
        }
        if (isSuccess) {
            globalNotifySuccess(isSuccessMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFailed, isSuccess]);

    //Delete Beneficiary >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const handleDeleteClick = (row) => {
        // console.log(row);
        setBeneficiaryMobile(row?.benefMobile);
        setBenefId(row?.id);
        setBenefIfsc(row?.benefIfsc);
        setBenefAcc(row?.benefAcc);
        setCustomerMobile(senderno);
        const beneficiary_mobile = row?.benefMobile;
        const customer_mobile = senderno;

        const showModal = () => {
            if (beneficiary_mobile && customer_mobile) {
                // setOpen(true);
            } else {
                setIsFailedMessage('Make sure you have filled all the fields');

                setIsFailed(true);
            }
        };

        showModal();

        // deleteBeneficiary();
    };

    const deleteBeneficiary = async () => {
        const beneficiary_mobile = beneficiaryMobile;
        const customer_mobile = customerMobile;
        setIsFailedMessage('');
        setIsSuccessMessage('');
        setIsFailed(false);
        setIsSuccess(false);
        setIsDeleted(false);
        try {
            const payload = { beneficiary_mobile, customer_mobile, beneficiary_ifsc, beneficiary_account, beneficiary_id };
            const response = await deleteBeneficiaryApi(payload);
            customerDetails();
            if (response?.data?.success) {
                setIsDeleted(true);
                setIsSuccess(true);
                setIsSuccessMessage(response?.data?.message);
            } else {
                setIsFailed(true);
                setIsFailedMessage(response?.data?.message);
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const filteredDatas = benfDetails?.filter((row) => {
        const benefName = row?.benefName?.toLowerCase(); // Convert name to lowercase
        const searchLowerCase = search?.toLowerCase(); // Convert search to lowercase

        return benefName?.includes(searchLowerCase);
    });

    return (
        <>
            <div>
                {/* <<<<<<<Breadcrumb starts here>>>>>> */}
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        ...commonStyles,
                        borderRadius: 2,
                        marginBottom: 2,
                        boxShadow: 2,
                        padding: '20px 10px',
                        '@media screen and (min-width: 600px)': {
                            padding: 2
                        }
                    }}
                >
                    <Typography variant="h3">
                        <GlobalBackButton /> Domestic Money Transfer
                    </Typography>

                    <Box sx={{ marginTop: '10px' }}>
                        <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <HomeOutlined />
                        </Link>

                        <Typography component="span" style={{ margin: '0 10px' }}>
                            &gt;
                        </Typography>
                        <Typography component="span">View Customer</Typography>
                    </Box>
                </Box>
                {/* <<<<<<<Breadcrumb end here>>>>>>> */}
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: 2
                    }}
                >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name : {data?.name ? data?.name : data?.data[0]?.name}</TableCell>
                                {/* {!isMobile && (
                                    <TableCell align="right">
                                        KYC: {data?.kycStatus == 0 || data?.data[0]?.kycStatus == 0 ? 'KYC Not Applied' : 'KYC Applied'}
                                    </TableCell>
                                )} */}
                                {!isMobile && (
                                    <TableCell align="right">
                                        Mobile: {data?.customermob ? data?.customermob : data?.data[0]?.customermob}
                                    </TableCell>
                                )}
                                {!isMobile && <TableCell align="right">MONTHLY LIMIT: {maxLimit ? maxLimit : '0'}</TableCell>}
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button color="primary" variant="contained" disableElevation sx={{ m: 2 }} onClick={() => addbeneficiary()}>
                        Add Beneficiary
                    </Button>
                    <Button color="primary" variant="contained" disableElevation sx={{ m: 2 }} onClick={() => ListBeneficiary()}>
                        List All Beneficiaries
                    </Button>
                    <Button color="primary" variant="contained" disableElevation sx={{ m: 2 }} onClick={() => ListMoneyTransfer()}>
                        List moneyTransfer
                    </Button>
                    <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                        <TextField
                            label="Search with Beneficiary Name"
                            name="mobile"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            variant="outlined"
                        />
                    </Stack>
                </div>
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: 2
                    }}
                >
                    <Table size={isMobile ? 'small' : 'medium'} aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sl. No</TableCell>
                                <TableCell align="right">Beneficiary Name</TableCell>
                                <TableCell align="right">IFSC</TableCell>
                                <TableCell align="right">Account Number</TableCell>
                                <TableCell align="right">Mobile Number</TableCell>
                                <TableCell align="center"></TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredDatas?.map((row, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="right">{row.benefName + ' ' + row.benefMname + ' ' + row.benefLname}</TableCell>
                                    <TableCell align="right">{row.benefIfsc}</TableCell>
                                    <TableCell align="right">{row.benefAcc}</TableCell>
                                    <TableCell align="right">{row.benefMobile}</TableCell>
                                    <TableCell align="center">
                                        <LoadingButton
                                            disabled={createLoading}
                                            // loading={loadingButtonIndex === index}
                                            color="primary"
                                            variant="contained"
                                            size={isMobile ? 'small' : 'medium'}
                                            onClick={() => {
                                                handleTransferClick(row, row.id);
                                            }}
                                        >
                                            Transfer
                                        </LoadingButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Popconfirm
                                            title="Delete Beneficiary"
                                            description="Are you sure you want to delete this beneficiary ?"
                                            onConfirm={confirm}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                style={{ marginLeft: '10px' }}
                                                color="error"
                                                variant="contained"
                                                size={isMobile ? 'small' : 'medium'}
                                                onClick={() => {
                                                    handleDeleteClick(row, index);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    shouldshowEsign={shouldshowEsign}
                />
            )}
            <Spinner loading={isCheckLoading || isLoading} />
        </>
    );
}

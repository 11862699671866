import React from 'react';
/* eslint-disable */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TextField } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { handleCatchError } from 'utils/CatchHandler';
import { customerEkycApi, ekycOtpVerifyApi, getCustomerOtpApi } from 'utils/API/Merchant APIs/DmtApiCalls';
import Spinner from 'views/Components/LoadingSinner';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { useNavigate } from 'react-router';
import Link from '@mui/material/Link';
const commonStyles = {
    m: 0
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function DmtEkycExistingCustomers({ showModal, datas, filteredData, index }) {
    const [open, setOpen] = React.useState(false);
    const [aadharno, setAadharno] = useState('');
    const [errorAadhar, setErrorAadhar] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [OTPPin, setOTPPin] = useState('');
    const [OtpId, setOtpId] = useState('');
    const [kycId, setKycId] = useState('');
    const [address, setAddress] = useState('');
    const [errorAddress, setErrorAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [errorPin, setErrorPin] = useState('');
    const navigate = useNavigate();
    const agentid = localStorage.getItem('id');
    const dmt_type = 2;
    console.log('showModal', showModal);
    //API CALL FOR EKYC
    const handleEkyc = async () => {
        try {
            setOTPPin('');
            setIsLoading(true);
            const CustomerMobileNo = datas?.customermob;
            const CustomerName = datas?.name;
            const AadharNo = aadharno;
            const Pid = 0;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                AadharNo,
                Pid,
                dmt_type
            };
            const response = await customerEkycApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setShowOtpField(response?.data?.success);
                setKycId(response?.data?.KYCRequestID);
                setOtpId(response?.data?.OTPRequestID);
            }
            if (response?.data?.ResponseCode === 2) {
                globalNotifyError('Please resent the otp');
                setKycId(response?.data?.KYCRequestID);
            } else if (response?.data?.ResponseCode === 101) {
                handleClose();
                const item = datas;
                navigate('/dmt2/dmt-beneficiarylist', { state: { item, filteredData, index } });
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API CALL FOR RESENT OTP
    const handleResentOtp = async () => {
        try {
            setOTPPin('');
            setIsLoading(true);
            const CustomerMobileNo = datas?.customermob;
            const CustomerName = datas?.name;
            const AadharNo = aadharno;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                dmt_type,
                AadharNo
            };
            const response = await getCustomerOtpApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setShowOtpField(response?.data?.success);
                setOtpId(response?.data?.OTPRequestID);
            } else if (response?.data?.ResponseCode === 101) {
                handleClose();
                const item = datas;
                navigate('/dmt2/dmt-beneficiarylist', { state: { item, filteredData, index } });
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API CALL FOR EKYC OTP VERIFY
    const handleEkycVErifyOtp = async () => {
        try {
            setIsLoading(true);
            const CustomerMobileNo = datas?.customermob;
            const CustomerName = datas?.name;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                OTPPin,
                OTPRequestId: OtpId,
                KYCRequestId: kycId,
                pincode,
                address,
                dmt_type
            };
            const response = await ekycOtpVerifyApi(payload);
            setIsLoading(false);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                const item = datas;
                navigate('/dmt2/dmt-beneficiarylist', { state: { item, filteredData, index } });
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    const handleClose = () => {
        setOpen(false);
        showModal(false);
    };
    useEffect(() => {
        if (showModal) {
            setOpen(true);
        }
    }, [showModal]);
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const validateAadhar = () => {
        const re = /^\d{12}$/;
        if (!re.test(aadharno)) {
            setErrorAadhar('Please enter a valid Aadhaar card number');
        } else {
            setErrorAadhar('');
        }
    };
    const validateAddress = () => {
        if (!address) {
            setErrorAddress('Please enter a valid address');
        } else {
            setErrorAddress('');
        }
    };
    const validatePin = () => {
        const re = /^\d{6}$/;
        if (!re.test(pincode)) {
            setErrorPin('Please enter a valid pincode');
        } else {
            setErrorPin('');
        }
    };
    const handleAadharChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 12);
        setAadharno(amountValue);
    };
    const handleOtpChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 6);
        setOTPPin(amountValue);
    };
    const handleAddress = (e) => {
        let inputValue = e.target.value.replace(/[^a-zA-Z0-9\s,.\-]/g, '');
        inputValue = inputValue.replace(/\s+/g, '');
        inputValue = inputValue.slice(0, 150);
        setAddress(inputValue);
    };
    const handlePinCodeChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 6);
        setPincode(amountValue);
    };
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    '& .MuiDialog-paper': {
                        width: '100%',
                        maxWidth: '600px',
                        margin: '0 auto',
                        padding: { xs: '10px', sm: '20px' },
                        '@media (max-width: 600px)': {
                            width: '90%'
                        }
                    }
                }}
            >
                <DialogTitle>{'Verify Your EKYC'}</DialogTitle>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        ...commonStyles,
                        borderRadius: 2,
                        marginBottom: 2,
                        padding: { xs: '10px', sm: '20px' }
                    }}
                >
                    {!showOtpField && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Aadhaar Number"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={aadharno}
                                        onChange={handleAadharChange}
                                        helperText={errorAadhar}
                                        error={Boolean(errorAadhar)}
                                        onBlur={validateAadhar}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Address"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={address}
                                        onChange={handleAddress}
                                        helperText={errorAddress}
                                        error={Boolean(errorAddress)}
                                        onBlur={validateAddress}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Pincode"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={pincode}
                                        onChange={handlePinCodeChange}
                                        helperText={errorPin}
                                        error={Boolean(errorPin)}
                                        onBlur={validatePin}
                                    />
                                </Grid>
                            </Grid>
                            <DialogActions>
                                {/* <Button variant="outlined" color="primary" onClick={handleClose}>
                                    Cancel
                                </Button> */}
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={isLoading || !aadharno || !address || !pincode}
                                    onClick={handleEkyc}
                                >
                                    Submit
                                </Button>
                            </DialogActions>
                        </>
                    )}
                    {showOtpField && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="OTP Number"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={OTPPin}
                                        onChange={handleOtpChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} container justifyContent="flex-end">
                                    <Link component="button" variant="body2" onClick={handleResentOtp}>
                                        Resend OTP
                                    </Link>
                                </Grid>
                            </Grid>
                            {/* <DialogActions> */}
                            <Button
                                variant="contained"
                                sx={{ mt: 1 }}
                                fullWidth
                                disabled={isLoading || !OTPPin}
                                onClick={handleEkycVErifyOtp}
                            >
                                Verify
                            </Button>
                            {/* </DialogActions> */}
                        </>
                    )}
                </Box>
            </Dialog>

            <Spinner loading={isLoading} />
        </>
    );
}

export default DmtEkycExistingCustomers;

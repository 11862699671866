/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist'); // Check localStorage at runtime
    return isBCWalletTrue === 'true' ? defaultTitle : bcTitle;
};
const getTitleLabel2 = () => {
    const label2 = localStorage.getItem('label2');
    return label2;
};
const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'super-distributor/dashboard',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons87',
            title: getTitle('BC Dashboard', 'Convert As BC Agent'),
            type: 'item',
            url: 'super-distributor/bc-dashboard-redirection',
            breadcrumbs: true
        },
        // {
        //     id: 'tabler-icons2',
        //     title: 'Profile',
        //     type: 'item',
        //     url: 'super-distributor/profile',
        //     breadcrumbs: true
        // },
        {
            id: 'tabler-icons211',
            title: 'All Merchants',
            type: 'item',
            url: 'super-distributor/allmerchants',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons22211',
            title: 'All Bc Agents',
            type: 'item',
            url: 'super-distributor/allbc',
            breadcrumbs: true
        },
        // {
        //     id: 'icons223122',
        //     title: 'Onboarding',
        //     type: 'collapse',
        //     children: [
        //         {
        //             id: 'tabler-icons312101',
        //             title: 'Onboarding',
        //             type: 'item',
        //             url: 'super-distributor/onboarding',
        //             breadcrumbs: true
        //         },
        //         {
        //             id: 'tabler-icons532211',
        //             title: 'List',
        //             type: 'item',
        //             url: '/super-distributor/listallmerchants',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'tabler-icons001',
            title: 'Soundbox',
            type: 'item',
            url: 'super-distributor/listsoundbox',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons002',
            title: 'BC Settlement List',
            type: 'item',
            url: 'super-distributor/listBcSettlements',
            breadcrumbs: true
        },
        // Temporary hide as per the requirement from product team
        // {
        //     id: 'tabler-icons0033',
        //     title: 'Counts',
        //     type: 'item',
        //     url: 'super-distributor/countlist',
        //     breadcrumbs: true
        // },
        {
            id: 'tabler-icons3',
            title: getTitleLabel2(),
            type: 'item',
            url: 'super-distributor/distributors',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons92',
            title: getTitleLabel3(),
            type: 'item',
            url: 'super-distributor/list-subdistributor',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons4',
            title: 'Wallet Request',
            type: 'item',
            url: 'super-distributor/walletrequest',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons5',
            title: 'Approve Wallet',
            type: 'item',
            url: 'super-distributor/approvewallet',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons6',
            title: 'Bank Details',
            type: 'item',
            url: 'super-distributor/bankdetails',
            breadcrumbs: true
        },
        {
            id: 'tabler-icon9',
            title: 'Incentive Eligible Report',
            type: 'item',
            url: 'super-distributor/incentivereport'
        },
        {
            id: 'tabler-icons7',
            title: 'Transaction History',
            type: 'item',
            url: 'super-distributor/transaction-history',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons8',
            title: 'My Transaction',
            type: 'item',
            url: 'super-distributor/my-transactions',
            breadcrumbs: true
        }
        //     ]
        // }
    ]
};

export default Dashboard;

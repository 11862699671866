import DmtTable from './bankingServices/money transfer/DmtFino/DmtTable';
import DmtTableIserveu from './bankingServices/money transfer/DMTIserveu/DmtTable';
import CreateCustomer from './bankingServices/money transfer/DmtFino/CreateCustomer';
import CreateCustomerIserveu from './bankingServices/money transfer/DMTIserveu/CreateCustomer';
import DmtTransfer from './bankingServices/money transfer/DmtFino/DmtTransfer';
import DmtTransferIserveu from './bankingServices/money transfer/DMTIserveu/DmtTransfer';
import CheckBeneficiaryStatus from './bankingServices/money transfer/DmtFino/CheckBeneficiaryStatus';
import CheckBeneficiaryStatusIserveu from './bankingServices/money transfer/DMTIserveu/CheckBeneficiaryStatus';
import DenseTable from './bankingServices/money transfer/DmtFino/TransferPage';
import DenseTableIserveu from './bankingServices/money transfer/DMTIserveu/TransferPage';
export const ConditionalRenderDmtTable = () => {
    const dmtType = 'fino';
    const reKyc = localStorage.getItem('reKyc');
    const rekycStatus = localStorage.getItem('rekycStatus');
    const data = { reKyc, rekycStatus };

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <DmtTableIserveu additionalData={data} />;
    } else {
        return <DmtTable additionalData={data} />;
    }
};

export const ConditionalRenderCreateCustomer = () => {
    const dmtType = 'fino';

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <CreateCustomerIserveu />;
    } else {
        return <CreateCustomer />;
    }
};

export const ConditionalRenderDmtTransfer = () => {
    const dmtType = 'fino';

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <DmtTransferIserveu />;
    } else {
        return <DmtTransfer />;
    }
};

export const ConditionalRenderCheckBenef = () => {
    const dmtType = 'fino';

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <CheckBeneficiaryStatusIserveu />;
    } else {
        return <CheckBeneficiaryStatus />;
    }
};

export const ConditionalRenderTransferPage = () => {
    const dmtType = 'fino';

    if (dmtType === 'iserveu' || dmtType === 'billavenue') {
        return <DenseTableIserveu />;
    } else {
        return <DenseTable />;
    }
};

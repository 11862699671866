/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { message, Popconfirm } from 'antd';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
const commonStyles = {
    m: 0
};
import { useState } from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';
import { useDispatch } from 'react-redux';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import {
    checkBenefExistApi,
    createBenefApi,
    createBeneficiaryApiCall,
    dmtCreateBeneFiciaryApi,
    verifyBeneficiaryApi
} from 'utils/API/Merchant APIs/DmtApiCalls';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
// import { Navigate } from 'react-router';
export default function CheckBeneficiaryStatus() {
    const dmt_Type = 2;
    const dispatch = useDispatch();
    const [isTrue, setIsTrue] = useState(null);
    const email = localStorage.getItem('email_id');
    const senderno = localStorage.getItem('sendernumber');
    const transfername = 'fino-transfer';
    const [AccountNo, setAccountNo] = useState('');
    let [benefname, setBenefname] = useState('');
    let [benelname, setBenelname] = useState('');
    const [MobileNo, setMobileNo] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [accError, setAccError] = useState('');
    const [ifscError, setIfscError] = useState('');
    const [pennyDropMessage, setPennyDropMessage] = useState('');
    const [dataFromPennyDrop, setDataFromPennyDrop] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPenny, setIsLoadingPenny] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const address = localStorage.getItem('address');
    const pin = localStorage.getItem('pin');
    const city = localStorage.getItem('city');
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.item || location?.state?.data;
    const firstName = dataFromPennyDrop?.BeneFName;
    const lastName = dataFromPennyDrop?.BeneLName;
    const [open, setOpen] = useState(false);
    const [verifiedData, setVerifiedData] = useState('');
    const [details, setDetails] = useState({
        AccountName: '',
        AccountNo: '',
        ifsc: ''
    });
    const handleClose = () => {
        setOpen(false);
    };
    //Alert antd
    const errorExists = () => {
        messageApi.open({
            type: 'warning',
            content: 'Account verified successfully, but the customer already exists !',
            style: {
                marginTop: '20vh'
            },
            duration: 5
        });
    };

    //API CALL FUNCTIONS

    //API TO CREATE BENEFICIARY
    const createBenes = async () => {
        setIsLoading(true);
        if (verifiedData) {
            const nameParts = verifiedData?.beneName?.split(' ');
            const firstName = nameParts[0];
            const lastName = nameParts[1];

            if (firstName && lastName) {
                benefname = firstName;
                benelname = lastName;
            }
        }
        try {
            const payload = {
                email,
                senderno,
                AccountNo,
                ifsc,
                MobileNo,
                pin,
                title,
                address,
                city,
                benefname,
                benelname,
                transfername,
                dmt_Type
            };
            const response = await createBeneficiaryApiCall(payload);
            const status = response?.data?.status;
            setIsLoading(false);
            if (status) {
                globalNotifySuccess(response?.data?.message);
                setMobileNo('');
                setBenefname('');
                setBenelname('');
                setIfsc('');
                setAccountNo('');
                setTitle('');
                navigate('/dmt2/dmt-beneficiarylist', { state: { status, data, senderno, created: 1 } });
            }
        } catch (error) {
            // setIsLoadingCreate(false);
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API TO CHECK THE BENEFICIARY EXISTS OR NOT
    const checkbeneficiary = async () => {
        try {
            setIsLoading(true);
            const payload = { email, senderno, AccountNo, ifsc, dmt_Type };
            const response = await checkBenefExistApi(payload);
            setIsLoading(false);
            if (response?.data?.status) {
                globalNotifySuccess(response?.data?.message);
            } else {
                createBenes();
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Function to call api for penny drop(ISERVEU PENNYDROP)
    const pennydropFunction = async () => {
        try {
            setIsLoadingPenny(true);
            const agentid = localStorage.getItem('id');
            const customerId = data?.id ? data?.id : data?.data[0]?.id;
            const name = benefname + ' ' + benelname;
            const accountno = AccountNo;
            const ifsccode = ifsc;
            const payload = {
                agentid,
                name,
                customerId,
                accountno,
                ifsccode,
                dmt_Type
            };
            console.log(payload, data);
            const response = await dmtCreateBeneFiciaryApi(payload);
            if (response?.data?.success) {
                dispatch(fetchWalletBalanceAsync());
                globalNotifySuccess(response?.data?.message);
                const verified_Data = response?.data?.data;
                setVerifiedData(verified_Data);
                setOpen(true);
                setDetails({
                    AccountName: response?.data?.data?.beneAccount,
                    AccountNo: response?.data?.data?.beneName,
                    Ifsc: response?.data?.data?.beneIFSC
                });
            }
            setIsLoadingPenny(false);
        } catch (error) {
            setIsLoadingPenny(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Helpers for pennydrop confirmation
    const confirm = (e) => {
        if (AccountNo && ifsc && !accError && !ifscError && benefname && benelname) {
            pennydropFunction();
        } else {
            if (!benefname || !benelname) {
                globalNotifyError('Please enter valid first and last name.');
            } else {
                setAccError('Please enter a valid Account Number');
                setIfscError('Please enter a valid IFSC Code');
            }
        }
    };
    const cancel = (e) => {
        console.log(e);
    };

    //FUNCTION TO HANDLE VALIDATION ERROR
    const validateMobile = () => {
        const re = /^[0-9]{10}$/;
        if (!re.test(MobileNo)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };

    const validateAccount = () => {
        if (!AccountNo) {
            setAccError('Please enter a valid Account Number');
        } else {
            setAccError('');
        }
    };

    const handleAccountChange = (e) => {
        const inputValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
        setAccountNo(inputValue);
    };
    const validateIfsc = () => {
        const re = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
        if (!re.test(ifsc)) {
            setIfscError('Please enter a valid IFSC code');
        } else {
            setIfscError('');
        }
    };

    const handleIfscChange = (e) => {
        setIfsc(e.target.value.toUpperCase());
    };
    const handleMobile = (e) => {
        const mobileValue = e.target.value;
        if (/^\d*$/.test(mobileValue) && mobileValue.length <= 10) {
            setMobileNo(mobileValue);
        }
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    const handleBenefFirstName = (event) => {
        const firstNameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
        if (event.target.value === '' || (event.target.value.length <= 20 && firstNameRegex.test(event.target.value))) {
            setBenefname(event.target.value);
        } else {
            globalNotifyError('Please enter a valid first name');
        }
    };

    const handleBenefLastName = (event) => {
        const lastNameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
        if (event.target.value === '' || (event.target.value.length <= 20 && lastNameRegex.test(event.target.value))) {
            setBenelname(event.target.value);
        } else {
            globalNotifyError('Please enter a valid last name');
        }
    };

    useEffect(() => {
        if (error) {
            globalNotifyError('All fields are Required');
            setError(false);
        }
    }, [error]);

    useEffect(() => {
        if (pennyDropMessage) {
            globalNotifyError(pennyDropMessage);
        }
    }, [pennyDropMessage]);

    useEffect(() => {
        //To automatically fill the fields in create benef

        if (showCreate) {
            const ifscCheck = ifsc?.toUpperCase();
            setBenefname(firstName);
            setBenelname(lastName);
            // setMobileNo(phoneNumber);
        }
    }, [showCreate, dataFromPennyDrop]);
    useEffect(() => {
        const handleCheckmerchant = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus) {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        handleCheckmerchant();
    }, []);

    return (
        <>
            {contextHolder}
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    marginBottom: 2,
                    boxShadow: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Domestic Money Transfer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>

                    <Link to="/dmt2/dmt-beneficiarylist" state={{ data }} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography component="span">View Customer</Typography>
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Check Beneficiary</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    ...commonStyles,
                    boxShadow: 2,
                    borderRadius: 2,
                    padding: 2,
                    mb: 1
                }}
            >
                <h2>Check Beneficiary</h2>
                {/* {showCreate ? ( */}
                <RadioGroup
                    sx={{
                        mt: 2,
                        ml: 1
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        value="Mrs"
                        control={<Radio />}
                        label="Mrs."
                        checked={title === 'Mrs'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Mr"
                        control={<Radio />}
                        label="Mr."
                        checked={title === 'Mr'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Ms"
                        control={<Radio />}
                        label="Ms."
                        checked={title === 'Ms'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </RadioGroup>
                {/* ) : null} */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={benefname}
                                    onChange={handleBenefFirstName}
                                    // onBlur={validateAadhaar}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={benelname}
                                    onChange={handleBenefLastName}
                                />
                            </Grid>
                        </>
                        <Grid item s={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Account Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={AccountNo}
                                helperText={accError}
                                error={Boolean(accError)}
                                onChange={handleAccountChange}
                                onBlur={validateAccount}
                            />
                        </Grid>
                        <Grid item s={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="IFSC Code"
                                variant="outlined"
                                fullWidth
                                required
                                value={ifsc}
                                helperText={ifscError}
                                error={Boolean(ifscError)}
                                onChange={handleIfscChange}
                                onBlur={validateIfsc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={MobileNo}
                                onChange={handleMobile}
                                helperText={errorMsg}
                                error={Boolean(errorMsg)}
                                onBlur={validateMobile}
                            />
                        </Grid>
                        <>
                            <Grid item xs={12} sm={6} lg={6}></Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Button
                                    variant="contained"
                                    type="primary"
                                    size="middle"
                                    disabled={isLoading || !MobileNo || !AccountNo || !benefname || !benelname || !ifsc}
                                    fullWidth
                                    onClick={checkbeneficiary}
                                    sx={{ mt: 3 }}
                                >
                                    Proceed Without Penny Drop
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Popconfirm
                                    title="Verify account by dropping a penny into it !"
                                    description="Using Penny Drop may cost you ₹4."
                                    onConfirm={confirm}
                                    onCancel={cancel}
                                    okText="Proceed"
                                    cancelText="Cancel"
                                >
                                    <Button
                                        size="middle"
                                        variant="contained"
                                        type="primary"
                                        disabled={isLoadingPenny || !MobileNo || !AccountNo || !benefname || !benelname || !ifsc}
                                        fullWidth
                                        sx={{ mt: 3 }}
                                    >
                                        Verify With Penny Drop
                                    </Button>
                                </Popconfirm>
                            </Grid>
                        </>
                        {/* )} */}
                    </Grid>
                </Box>
            </Box>

            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal isOpen={merchantModalOpen} handleclose={handleBcModalClose} isPending={isBcConvertPending} />
            )}
            <Spinner loading={isCheckLoading} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{'Verify your details?'}</DialogTitle>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        marginBottom: 2,
                        padding: { xs: '10px', sm: '20px' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2 // spacing between fields
                    }}
                >
                    <TextField fullWidth label="Account Name" variant="outlined" disabled value={details?.AccountName} required />
                    <TextField fullWidth label="Account No" variant="outlined" disabled value={details?.AccountNo} required />
                    <TextField
                        fullWidth
                        label="IFSC"
                        variant="outlined"
                        disabled
                        value={details?.Ifsc || ''}
                        required
                        InputLabelProps={{
                            shrink: true // Ensures the label stays above the input field
                        }}
                    />
                </Box>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={createBenes} disabled={isLoading || isLoadingPenny}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
